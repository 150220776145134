/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import AOS from 'aos'
import Footer from './Footer/footer'
import Header from "./Header/header"
import "./layout.css"


class Layout extends React.Component{
  constructor(props){
    super(props);

  }
  componentDidMount(){
    AOS.init();
  }

  render(){

    return (
      <>
      <Header/>
        <div className="page-content">
          <main>{this.props.children}</main>
          <Footer />
        </div>
      </>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
